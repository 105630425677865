import axios from 'axios'
import { API } from '../API'

export function getProductsList() {

    const response = axios.get(API.PRODUCTS_URL)
        .then(res => {
            return res
        })
        .catch(err => {
            return err
        })

    return response
}

export function updateProductsList(jwt, body, id) {

    const response = axios.put(API.PRODUCTS_URL + '/' + id, body, {
        headers: {
            'Authorization': `Bearer ${jwt}`
        }
    })
        .then(res => {
            return res
        })
        .catch(err => {
            return err
        })

    return response
}

export function deleteProductService(jwt, id) {

    const response = axios.delete(API.PRODUCTS_URL + '/' + id, {
        headers: {
            'Authorization': `Bearer ${jwt}`
        }
    })
        .then(res => {
            return res
        })
        .catch(err => {
            return err
        })

    return response
}

export function uploadProductsList(jwt, formData) {

    const response = axios.post(API.PRODUCTS_URL, formData, {
        headers: {
            'Authorization': `Bearer ${jwt}`
        }
    })
        .then(res => {
            return res
        })
        .catch(err => {
            return err
        })

    return response
}

export function getOrderDetails(jwt) {
    const response = axios.get(API.ORDERS, {
        headers: {
            'Authorization': `Bearer ${jwt}`
        }
    }
    )
        .then(res => {
            return res
        })
        .catch(err => {
            return err
        })

    return response
}

export function updateOrderDetails(jwt, value, userId) {

    const body = {
        order_completed: value
    }

    const response = axios.put(API.UPDATE_ORDER + userId, body, {
        headers: {
            'Authorization': `Bearer ${jwt}`
        }
    }
    )
        .then(res => {
            return res
        })
        .catch(err => {
            return err
        })

    return response
}

export function getShopAddress() {
    const response = axios.get(API.SHOP_ADDRESS)
        .then(res => {
            return res
        })
        .catch(err => {
            return (
                {
                    "shop_name": "Jagdamba Dairy",
                    "email": "Error Getting Email",
                    "address": "Error Getting Address",
                    "phone_no": '0000000000',

                }
            )
        })

    return response
}

export function updateShopAddress(body, jwt) {
    const response = axios.put(API.SHOP_ADDRESS, body, {
        headers: {
            'Authorization': `Bearer ${jwt}`
        }
    })
        .then(res => {
            return res
        })
        .catch(err => {
            return (
                {
                    "shop_name": "Jagdamba Dairy",
                    "email": "Error Getting Email",
                    "address": "Error Getting Address",
                    "phone_no": '0000000000',

                }
            )
        })

    return response
}

export function getTakingOrderStatus() {
    const response = axios.get(API.TAKING_ORDER_INFO)
        .then(res => {
            return res.data.isTakingOrder
        })
        .catch(err => {
            console.log(err)
            return ("Error")
        })

    return response
}

export function updateTakingOrderStatus(body, jwt) {
    const response = axios.put(API.TAKING_ORDER_INFO, body, {
        headers: {
            'Authorization': `Bearer ${jwt}`
        }
    })
        .then(res => {
            return res.data.isTakingOrder
        })
        .catch(err => {
            return ("Error")
        })

    return response
}

export function searchInObject(object, value) {
    const result = object.find(o => o.id === value);
    return result
}