import React, { useState, useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import PageTitle from "../../components/PageTitle/PageTitle";
import { getShopAddress, updateShopAddress } from '../../services/services'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 700,
    },
}));


export default function ShopInfo() {

    const [loading, setLoading] = useState(true)
    const [refresh, setRefresh] = useState(0)
    const [shopName, setShopName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [uploading, setUploading] = useState(false)
    const [disableBtns, setDisableBtns] = useState(false)


    const classes = useStyles();


    const refreshPage = () => {
        setRefresh(refresh + 1)
    }

    async function getShopInfo() {
        setLoading(true)
        const shopinfo = await getShopAddress()
        if (shopinfo.data) {
            setShopName(shopinfo.data.shop_name)
            setEmail(shopinfo.data.email)
            setAddress(shopinfo.data.address)
            setPhone(shopinfo.data.phone_no)
        }
        setLoading(false)
    }

    useEffect(() => {
        getShopInfo()
    }, [refresh])


    const updateShopInfo = async () => {
        setLoading(true)

        const body = {
            shop_name: shopName,
            email: email,
            address: address,
            phone_no: phone
        }

        const jwt = await localStorage.getItem('jwt')
        const response = await updateShopAddress(body, jwt)

        if (response.status === 200) {
            alert("Updated Successfully")
            refreshPage()
            setLoading(false)
            setDisableBtns(false)
        } else {
            setLoading(false)
            alert("Some issue occured")
        }
    }

    if (loading) {
        return (<LinearProgress />)
    }

    return (
        <>
            <PageTitle title="Shop Info" button="Update Info" onClick={updateShopInfo} />

            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xl>
                                    <TextField style={classes.productTitle} id="shopname" label="Shop Name" variant="outlined" value={shopName} onChange={(e) => setShopName(e.target.value)} />
                                    <TextField
                                        style={
                                            {

                                                marginTop: '2%',
                                                marginBottom: '2%',
                                                width: '100%'
                                            }
                                        }
                                        id="address"
                                        label="Address"
                                        variant="outlined"
                                        multiline
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}

                                    />
                                    <TextField style={{
                                        marginRight: '2%',
                                        marginBottom: '2%'
                                    }} id="email" label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <TextField style={{
                                        marginBottom: '2%'
                                    }} id="phone_no" label="Phone Number" variant="outlined" value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </>
    );
}
