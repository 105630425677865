import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { updateOrderDetails } from '../../services/services'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});


export default function OrderEditPage(props) {
    console.log(props)

    const classes = useStyles();
    const { onClose, values, open } = props;
    const [uploading, setUploading] = useState(false)
    const [canceling, setCanceling] = useState(false)
    const [pending, setPending] = useState(false)
    const [disableBtns, setDisableBtns] = useState(false)

    const handleClose = () => {
        onClose(values);
    };

    const markOrderCompleted = async () => {
        setUploading(true)
        setDisableBtns(true)
        const jwt = await localStorage.getItem('jwt')
        const id = values.id
        const result = await updateOrderDetails(jwt, true, id)
        if (result.status === 200) {
            alert("Updated Successfully")
            setUploading(false)
            setDisableBtns(false)
            onClose(values)
        } else {
            setUploading(false)
            setDisableBtns(false)
            alert("Some issue occured")
            onClose(values)
        }
    }
    const pendingOrder = async () => {
        setPending(true)
        setDisableBtns(true)
        const jwt = await localStorage.getItem('jwt')
        const id = values.id
        const result = await updateOrderDetails(jwt, false, id)
        if (result.status === 200) {
            setPending(false)
            setDisableBtns(false)
            alert("Updated Successfully")
            onClose(values)
        } else {
            setPending(false)
            setDisableBtns(false)
            alert("Some issue occured")
            onClose(values)
        }
    }

    const cancelOrder = async () => {
        setCanceling(true)
        setDisableBtns(true)
        const jwt = await localStorage.getItem('jwt')
        const id = values.id
        const result = await updateOrderDetails(jwt, null, id)
        if (result.status === 200) {
            setCanceling(false)
            setDisableBtns(false)
            alert("Updated Successfully")
            onClose(values)
        } else {
            setCanceling(false)
            setDisableBtns(false)
            alert("Some issue occured")
            onClose(values)
        }
    }

    const orderedProduct = values.order_details

    return (
        <>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Order Details
                        </Typography>
                        <Button autoFocus
                            style={{ backgroundColor: '#5cd4a1', color: 'white', marginRight: 5 }}
                            onClick={markOrderCompleted}
                            disabled={disableBtns}
                        >
                            {uploading ? <CircularProgress /> :
                                'Mark as completed'
                            }
                        </Button>

                        <Button autoFocus
                            style={{ backgroundColor: '#fac25f', color: 'white', marginRight: 5 }}
                            onClick={pendingOrder}
                            disabled={disableBtns}
                        >
                            {pending ? <CircularProgress /> :
                                'Mark as Pending'
                            }
                        </Button>

                        <Button autoFocus
                            style={{ backgroundColor: '#ef5892', color: 'white', marginRight: 5 }}
                            onClick={cancelOrder}
                            disabled={disableBtns}
                        >
                            {canceling ? <CircularProgress /> :

                                'Cancel Order'
                            }
                        </Button>
                    </Toolbar>
                </AppBar>

                <Typography variant="h4" style={{ textAlign: 'center', marginTop: '2%' }}>
                    User Details
                </Typography>

                <Table aria-label="Ordered Products Details" style={{ width: '40%', marginLeft: '30%', marginTop: '1%' }} component={Paper}>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{values.username}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Address</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{values.user_address}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Order Date</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{values.order_date}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Phone Number</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{values.phone_no}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Payment Mode</TableCell>
                        <TableCell></TableCell>
                        <TableCell>{values.payment_mode}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Order Status</TableCell>
                        <TableCell></TableCell>
                        <TableCell style={{ color: values.order_completed ? 'green' : values.order_completed === false ? '#fac25f' : '#ef5892', fontWeight: 'bold' }}>{values.order_completed ? 'Completed' : values.order_completed === false ? 'Pending' : 'Cancelled'}</TableCell>
                    </TableRow>
                </Table>

                <Typography variant="h4" style={{ textAlign: 'center', marginTop: '2%' }}>
                    Products Ordered
                </Typography>


                <Table aria-label="Ordered Products Details" style={{ width: '40%', marginLeft: '30%', marginTop: '1%', marginBottom: '3%' }} component={Paper}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Product Qty</TableCell>
                            <TableCell>Product Base Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            orderedProduct ? orderedProduct.map(value => (
                                <>
                                    <TableRow key={value.id}>
                                        <TableCell component="th" scope="row">
                                            {value.cart_item_name}
                                        </TableCell>
                                        <TableCell>{value.qty} {value.product_unit}</TableCell>
                                        <TableCell>₹{value.amount}</TableCell>
                                    </TableRow>
                                </>
                            )) : null
                        }
                    </TableBody>
                    <TableHead>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell></TableCell>
                            <TableCell>₹{values.total_cost}</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>



            </Dialog>
        </>
    );
}

