const BASE_URL = 'https://jagdamba-api-quartilelabs.herokuapp.com'
const QL_SERVICE = 'http://reset-password.quartilelabs.com:8080'

export const API = {
    BASE_URL: BASE_URL,
    CART_API: BASE_URL + '/user-carts',
    GET_CART: BASE_URL + '/users',
    PRODUCTS_URL: BASE_URL + '/Products',
    TAKING_ORDER_INFO: BASE_URL + '/taking-order-info',
    CHECKOUT_API: BASE_URL + '/orders',
    LOGIN_ADMIN: BASE_URL + '/admin/auth/local',
    ORDERS: BASE_URL + '/orders',
    UPDATE_ORDER: BASE_URL + '/orders/',
    UPDATE_CHECK: BASE_URL + '/current-app-version',
    SHOP_ADDRESS: BASE_URL + '/shop-address'
}

export const DEVELOPER_SITE = "http://quartilelabs.com"