import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import AddProducts from "../../pages/addproduct/AddProducts";
import ProductOrders from "../../pages/productorders/ProductOrders";
import ShopInfo from "../../pages/ShopInfo/ShopInfo";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/product-orders" component={ProductOrders} />
            <Route path="/app/add-product" component={AddProducts} />
            <Route path="/app/shop-info" component={ShopInfo} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
