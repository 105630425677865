import React, { useState, useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUIDataTable from "mui-datatables";
// components
import PageTitle from "../../components/PageTitle/PageTitle";

import { getProductsList, updateProductsList, searchInObject, deleteProductService } from '../../services/services'
import { API } from '../../API'
import AddNewProduct from './AddNewProduct'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 700,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


const columns = [
  {
    name: "product_title",
    label: "Title",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "product_price",
    label: "Price(₹)",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "product_unit",
    label: "Unit",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "product_description",
    label: "Description",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "is_available",
    label: "Available",
    options: {
      filter: true,
      sort: true,
      display: 'false'
    },
  },
  {
    name: "id",
    label: "id",
    options: {
      filter: true,
      sort: true,
      display: 'excluded'
    },
  }
];



export default function AddProducts() {

  const [allProducts, setAllProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(0)
  const [keys, setKeys] = useState()
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [unit, setUnit] = useState('')
  const [minqty, setMinqty] = useState('')
  const [isAvailable, setIsAvailable] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [uploading, setUploading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [disableBtns, setDisableBtns] = useState(false)
  const [productId, setProductId] = useState('')
  const [addProduct, setAddProducts] = useState(false)
  const [selectedFile, setSelectedFile] = useState('')

  const classes = useStyles();


  const refreshPage = () => {
    setRefresh(refresh + 1)
  }

  async function getAllProducts() {
    setLoading(true)
    const allproduct = await getProductsList()
    if (allproduct.status === 200) {
      if (allproduct.data.length > 0) {
        let keys_local = Object.keys(allproduct.data[0]).map(i => i.toUpperCase());
        setKeys(keys_local)
        setAllProducts(allproduct.data.reverse())
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    getAllProducts()
  }, [refresh])


  const handleClickOpen = (e) => {
    const result = searchInObject(allProducts, e[e.length - 1])
    setTitle(result.product_title)
    setDescription(result.product_description)
    setPrice(result.product_price)
    setUnit(result.product_unit)
    setMinqty(result.product_min_qty)
    setIsAvailable(result.is_available)
    setImageUrl(result.product_main_img ? result.product_main_img.provider === 'local' ? API.BASE_URL + result.product_main_img.url : result.product_main_img.url : require('./select-image.png'))
    setProductId(result.id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveChanges = async () => {
    setUploading(true)
    const body = {
      product_title: title,
      product_description: description,
      product_price: price,
      product_unit: unit,
      product_min_qty: minqty,
      is_available: isAvailable
    }

    const formData = new FormData()
    formData.append('data', JSON.stringify(body))
    if (selectedFile !== '') {
      formData.append('files[]product_main_img', selectedFile, selectedFile.name)
    }

    const jwt = await localStorage.getItem('jwt')
    const id = productId
    const response = await updateProductsList(jwt, formData, id)

    if (response.status === 200) {
      alert("Updated Successfully")
      setUploading(false)
      setDisableBtns(false)
      getAllProducts()
      handleClose()
    } else {
      setUploading(false)
      setDisableBtns(false)
      alert("Some issue occured")
      handleClose()
    }
  }

  const deleteProduct = async () => {
    setDeleting(true)

    const jwt = await localStorage.getItem('jwt')
    const id = productId
    const response = await deleteProductService(jwt, id)

    if (response.status === 200) {
      alert("Deleted Successfully")
      setDeleting(false)
      setDisableBtns(false)
      getAllProducts()
      handleClose()
    } else {
      setUploading(false)
      setDeleting(false)
      alert("Some issue occured")
      handleClose()
    }
  }

  const handleFileClick = e => {
    document.getElementById('fileInput').click();
  }

  const handleFileChange = e => {
    setSelectedFile(e.target.files[0])
    setImageUrl(URL.createObjectURL(e.target.files[0]))
  }

  const addNewProduct = () => {
    setAddProducts(true)
  }
  const closeAddProduct = (action) => {
    setAddProducts(false);
    if (action) {
      getAllProducts()
    }
  };
  if (loading) {
    return (<LinearProgress />)
  }

  return (
    <>
      <PageTitle title="Products" button="Add Product" onClick={addNewProduct} />
      <MUIDataTable
        data={allProducts}
        columns={columns}
        options={{
          filterType: "checkbox",
          onRowClick: (e) => {
            handleClickOpen(e)
          }
        }}
      />
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Product Detail
                        </Typography>
            <Button autoFocus
              style={{ backgroundColor: '#5cd4a1', color: 'white', marginRight: 5 }}
              onClick={saveChanges}
              disabled={disableBtns}
            >
              {uploading ? <CircularProgress /> :
                'Save Changes'
              }
            </Button>

            <Button autoFocus
              style={{ backgroundColor: '#ef5892', color: 'white', marginRight: 5 }}
              onClick={deleteProduct}
              disabled={disableBtns}
            >
              {deleting ? <CircularProgress /> :

                'Delete Product'
              }
            </Button>
          </Toolbar>
        </AppBar>

        <Typography variant="h4" style={{ textAlign: 'center', marginTop: '2%', marginBottom: '2%' }}>
          Edit Details
                </Typography>

        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item>
                <ButtonBase className={classes.image} onClick={handleFileClick}>
                  <img className={classes.img} alt="complex" src={imageUrl} />
                  <input type="file" id="fileInput" onChange={handleFileChange} style={{ display: 'none' }} />
                </ButtonBase>
                <h5>click to change image</h5>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xl>
                    <TextField style={classes.productTitle} id="title" label="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
                    <TextField
                      style={
                        {

                          marginTop: '2%',
                          marginBottom: '2%',
                          width: '100%'
                        }
                      }
                      id="desription"
                      label="Description"
                      variant="outlined"
                      multiline
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}

                    />
                    <TextField style={{
                      marginRight: '2%',
                      marginBottom: '2%'
                    }} id="price" label="Price(in ₹)" variant="outlined" value={price} onChange={(e) => setPrice(e.target.value)} />
                    <TextField style={{
                      marginBottom: '2%'
                    }} id="unit" label="Unit" variant="outlined" value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                    />
                    <TextField style={{
                      marginBottom: '2%'
                    }} id="min_qty" label="Min qty" variant="outlined" value={minqty}
                      onChange={(e) => setMinqty(e.target.value)}
                    />
                    <FormControlLabel
                      control={<IOSSwitch checked={isAvailable} onChange={(e) => { setIsAvailable(e.target.checked) }} />}
                      label="Available"
                      style={{ marginLeft: '2%', border: '1px solid #c4c4c4', borderRadius: 2, padding: '1%' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Dialog>

      <AddNewProduct open={addProduct} onClose={closeAddProduct} />
    </>
  );
}
