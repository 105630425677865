import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { uploadProductsList } from '../../services/services'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { API } from '../../API'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 700,
    },
    image: {
        width: 128,
        height: 128,
        border: '1px solid silver'
    },
    imageInput: {
        width: 128,
        height: 128,
        opacity: 0
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});


export default function AddNewProduct(props) {
    console.log(props)

    const classes = useStyles();
    const { onClose, open } = props;
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [unit, setUnit] = useState('')
    const [minqty, setMinqty] = useState('')
    const [isAvailable, setIsAvailable] = useState(true)
    const [uploading, setUploading] = useState(false)
    const [disableBtns, setDisableBtns] = useState(false)
    const [previewImage, setPreviewImage] = useState(require('./select-image.png'))
    const [selectedFile, setSelectedFile] = useState('')

    const handleClose = () => {
        onClose();
    };

    const handleFileClick = e => {
        document.getElementById('fileInput').click();
    }

    const handleFileChange = e => {
        setSelectedFile(e.target.files[0])
        setPreviewImage(URL.createObjectURL(e.target.files[0]))
    }

    const uploadProduct = async () => {

        setUploading(true)
        setDisableBtns(true)

        const jwt = await localStorage.getItem('jwt')
        const formData = new FormData()
        const body = {
            product_title: title,
            product_description: description,
            product_price: price,
            product_unit: unit,
            product_min_qty: minqty,
            is_available: isAvailable
        }
        formData.append('data', JSON.stringify(body))
        if (selectedFile !== '') {
            formData.append('files[]product_main_img', selectedFile, selectedFile.name)
        } else {
            alert('Please select image')
            setUploading(false)
            setDisableBtns(false)
            return
        }

        const response = await uploadProductsList(jwt, formData)

        if (response.status === 200) {
            alert("Updated Successfully")
            setUploading(false)
            setDisableBtns(false)
            onClose(true)
        } else {
            setUploading(false)
            setDisableBtns(false)
            alert("Some issue occured")
            onClose(false)
        }
    }

    return (
        <>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Add New Product
                        </Typography>
                        <Button autoFocus
                            style={{ backgroundColor: '#5cd4a1', color: 'white', marginRight: 5 }}
                            onClick={uploadProduct}
                            disabled={disableBtns}
                        >
                            {uploading ? <CircularProgress /> :
                                'Save Changes'
                            }
                        </Button>
                    </Toolbar>
                </AppBar>

                <Typography variant="h4" style={{ textAlign: 'center', marginTop: '2%' }}>
                    Enter Details
                </Typography>

                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <ButtonBase className={classes.image} onClick={handleFileClick}>
                                    <input type="file" id="fileInput" onChange={handleFileChange} style={{ display: 'none' }} />
                                    <img className={classes.image} src={previewImage} />
                                </ButtonBase>
                                <h5>click to select image</h5>
                            </Grid>
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xl>
                                        <TextField style={classes.productTitle} id="title" label="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        <TextField
                                            style={
                                                {

                                                    marginTop: '2%',
                                                    marginBottom: '2%',
                                                    width: '100%'
                                                }
                                            }
                                            id="desription"
                                            label="Description"
                                            variant="outlined"
                                            multiline
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                        <TextField style={{
                                            marginRight: '2%',
                                            marginBottom: '2%'
                                        }} id="price" label="Price(in ₹)" variant="outlined" value={price} onChange={(e) => setPrice(e.target.value)} />
                                        <TextField style={{
                                            marginBottom: '2%'
                                        }} id="unit" label="Unit" variant="outlined" value={unit} onChange={(e) => setUnit(e.target.value)} />
                                        <TextField style={{
                                            marginBottom: '2%'
                                        }} id="min_qty" label="Min qty" variant="outlined" value={minqty} onChange={(e) => setMinqty(e.target.value)} />
                                        <FormControlLabel
                                            control={<IOSSwitch checked={isAvailable} onChange={(e) => { setIsAvailable(e.target.checked) }} />}
                                            label="Available"
                                            style={{ marginLeft: '2%', border: '1px solid #c4c4c4', borderRadius: 2, padding: '1%' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Dialog>
        </>
    );
}
