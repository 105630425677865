import React, { useState, useEffect, useStyles } from "react";
import {
  Grid, LinearProgress,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { getOrderDetails, updateOrderDetails, searchInObject } from '../../services/services'
import OrderEditPage from './OrderEditPage'

const columns = [
  {
    name: "username",
    label: "Name",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "user_email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "user_address",
    label: "Address",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "payment_mode",
    label: "Payment Mode",
    options: {
      filter: true,
      sort: true,
      display: 'false'
    },
  },
  {
    name: "total_cost",
    label: "Amount",
    options: {
      filter: true,
      sort: true,
      display: 'false'
    },
  },
  {
    name: "locality",
    label: "Locality",
    options: {
      filter: true,
      sort: true,
      display: 'false'
    },
  },
  {
    name: "phone_no",
    label: "Phone No",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "id",
    label: "id",
    options: {
      filter: true,
      sort: true,
      display: 'excluded'
    },
  }
];

export default function ProductOrders() {

  const [allOrders, setAllOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(0)
  const [open, setOpen] = React.useState(false);
  const [singleValue, setSingleValue] = useState([])

  const refreshPage = () => {
    setRefresh(refresh + 1)
  }

  async function getAllOrders() {
    setLoading(true)
    const jwt = await localStorage.getItem('jwt')
    const allOrder = await getOrderDetails(jwt)
    console.log(allOrder)
    if (allOrder.status === 200) {
      if (allOrder.data.length > 0) {
        let keys_local = Object.keys(allOrder.data[0]).map(i => i.toUpperCase());
        setAllOrders(allOrder.data.reverse())
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    getAllOrders()
  }, [refresh])

  const handleClickOpen = (e) => {
    const result = searchInObject(allOrders, e[e.length - 1])
    setSingleValue(result)
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  if (loading) {
    return (<LinearProgress />)
  }

  return (
    <>
      <PageTitle title="Orders" button="Refresh" onClick={refreshPage} />
      <MUIDataTable
        data={allOrders}
        columns={columns}
        options={{
          filterType: "checkbox",
          onRowClick: (e) => {
            handleClickOpen(e)
          }
        }}
      />

      <OrderEditPage values={singleValue} open={open} onClose={handleClose} />

    </>
  );
}
